import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title = "Sound Match";
  }, [step]);



  const handleSubmitCredentials = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response = await fetch('https://api.cklordconsulting.com/api/user/send-otp-account-deletion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      }).then((response) => {
        if (!response.ok) {
          throw new Error('HTTP error!');
        }
        return response.json();
      });
      console.log("OK", response.status);
      if (response && response.status === "Success") {
        // Save email to localStorage
        localStorage.setItem('email', email);

        setStep(2);
        setLoading(false);
        setMessage('');
      }
      if (response && response.status === "Failed") {
        setMessage('Invalid credentials. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      setMessage('Error sending OTP. Please try again.');
      setLoading(false);
    }
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    try {
      // Retrieve email from localStorage
      const storedEmail = localStorage.getItem('email');

      let response = await fetch('https://api.cklordconsulting.com/api/user/verify-otp-account-deletion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: storedEmail, otp }),
      }).then((response) => {
        if (!response.ok) {
          throw new Error('HTTP error!');
        }
        return response.json();
      });

      if (response && response.status === "Success") {
        setMessage('Account successfully deleted.');
        setStep(1);
        setLoading(false);
        localStorage.removeItem('email');
        setEmail('');
        setPassword('');
        setOtp('');
        setTimeout(() => {
          setMessage('');
        }, 5000)

      } else {
        setMessage('Invalid OTP or error verifying OTP. Please try again.');
      }
    } catch (error) {
      setMessage('Invalid OTP or error verifying OTP. Please try again.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={'https://imgur.com/W1pAroN.png'} alt="logo" style={{
          width: '30%'
        }} />

        {step === 1 && (
          <div style={{
            paddingLeft: '20px',
            paddingRight: '20px',
            maxWidth: '100%%',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <form onSubmit={handleSubmitCredentials}
              style={{
                width: '100%'
              }}>
              <h3 style={{ color: 'red' }}>This form is for account deletion.
                Enter Your Email and Password here to delete your account
              </h3>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <div>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                <button type="button" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? 'Hide' : 'Show'} Password
                </button>
              </div>
              <button type="submit">
                {loading ? 'Loading...' : 'Submit'}
              </button>
              {message && <p style={{ fontWeight: 800 }}>{message}</p>}

            </form>
          </div>

        )}

        {step === 2 && (
          <form onSubmit={handleSubmitOtp}>
            <h3>Enter OTP</h3>
            <p>
              We have sent an email to your Gmail account with an OTP (One-Time Password).
              Please check your inbox and enter the OTP here to confirm your account.
              This step is necessary to complete the verification process.
            </p>
            <input
              type="number"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="OTP"
              required
            />
            <button type="submit">Verify OTP</button>
            {message && <p style={{ fontWeight: 800 }}>{message}</p>}
          </form>
        )}

      </header>
    </div>
  );
}

export default App;
